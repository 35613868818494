import React from "react"
import "../style.css"
import Info from "../components/info"
import Window from '../components/window';
import Appbar from '../components/appbar';
import { Helmet } from "react-helmet"

function index() {
  return (
    <div>
      <Window />
      <Info />
      <Appbar />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ross | Thomson</title>
      </Helmet>
    </div>
  )
}

export default index
