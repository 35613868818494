import React from "react"
import ProfileImg from "../images/profile.png";

export default function info() {
  return (
    <div className="container">
      <div className="my-5"></div>
      <div className="display-3 profile-heading">
        <div style={{ display: "inline-block" }}>
          <span style={{ color: "white" }}>Hello, I'm</span> <br />
        Ross.
        </div>
        <div style={{ display: "inline-block", marginLeft: "20px" }}>
          <img src={ProfileImg} width="200px" />
        </div>
      </div>
      <div className="h1 code mt-4 mb-3">aboutMe {"{"}</div>
      <div className="text-muted mx-5 my-4 h3 text-justify info">
        <p>I am a full-stack web developer working and living in Sydney Australia 🐨</p>
        <p>I work with technologies including React, GraphQL, Node and AWS</p>
        <p>Some of my interests are learning languages 💬, cooking and eating good food 🍲 and travelling 🛫</p>
      </div>
      <div className="h1 code mt-2 mb-3">{"}"}</div>
    </div>
  )
}
