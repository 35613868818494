import React from 'react';
import styled from '@emotion/styled';
import {
    FaGithub as Github,
    FaLinkedin as LinkedIn,
    FaEnvelope as Mail,
    FaInstagram as Instagram,
} from "react-icons/fa"

const AppbarStyles = styled.div`
    position: fixed;
    bottom: 10px;
    width: 100%;

.appbar-container {
    display: flex;
    justify-content: center;
    width: 100%;

    .appbar {
        background: rgb(203,206,211);
        border-radius: 5px;
        padding: 10px;
    }
}
`;

const Appbar = () => {
    return (
        <AppbarStyles>
            <div class="appbar-container">
                <div class="appbar">
                    <a className="mr-4 icon" href="https://github.com/RossThomson">
                        <Github size="50px" />
                    </a>
                    <a className="mr-4 icon" href="https://linkedin.com/in/rosswilliamthomson">
                        <LinkedIn size="50px" />
                    </a>
                    <a className="mr-4 icon" href="https://www.instagram.com/ross_thomson01">
                        <Instagram size="50px" />
                    </a>
                    <a className="icon" href="mailto:thomson360@gmail.com">
                        <Mail size="50px" />
                    </a>
                </div>
            </div>
        </AppbarStyles>
    );
}

export default Appbar;